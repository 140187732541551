<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <audanet-task-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tags has-addons is-pulled-right">
              <span class="tag is-dark">Last fetched</span>
              <span v-if="!isTableLoading"
                class="tag is-success">{{ $filters.formatDateTimeLocale(new Date(), $userInfo.locale) }}</span>
            </div>
            <a class="button is-primary"
              @click="filterList">
              <span class="icon">
                <i class="mdi mdi-cloud-download mdi-18px" />
              </span>
              <span>Fetch</span>
            </a>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(task, index) in tasks"
                :key="task.taskId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  {{ task.claimNo }}
                </td>
                <td>
                  {{ task.displayName }}
                </td>
                <td>
                  {{ task.taskStatus }}
                </td>
                <td>
                  {{ task.taskType }}
                </td>
                <td>
                  {{ task.businessStatusText }}
                </td>
                <td>
                  {{ task.quoteNumbers }}
                </td>
                <td>
                  {{ $filters.formatDateTimeLocale(task.creationDate, $userInfo.locale) }}
                </td>
                <td v-if="isAutoSyncEnabled">
                  {{ $filters.formatDateTimeLocale(task.syncedDate, $userInfo.locale) }}
                </td>
                <td class="is-content-width">
                  <div class="has-text-centered">
                    <div class="tooltip is-tooltip-topright"
                      :data-tooltip="getIconToolTip(task.businessStatusText,audanetSetting)">
                      <div v-if="canLoadMessage"
                        class="button is-success is-small is-inverted "
                        :disabled="checkSettingStatus(task.businessStatusText,audanetSetting)"
                        @click="!checkSettingStatus(task.businessStatusText,audanetSetting) && load(index)">
                        <span class="icon is-medium">
                          <i class="mdi mdi-download mdi-24px" />
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <load-quote-modal :active.sync="isLoadQuoteModalActive"
      @close="closeModal()"
      @previous="previous()"
      @next="next()"
      @load="loadTask()"
      :show-icon="false"
      :previous-disabled="currentStep === steps.CreateAssign.id"
      :next-disabled="currentStep === lastStep.id"
      :continue-disabled="!enableContinue">
      <p slot="text-title">AudaNet Load Task</p>
      <div slot="text-content">
        <load-quote-steps v-model="loadOptions"
          :current-step="currentStep"
          :steps="steps"
          ecom-type="audanet" />
      </div>
    </load-quote-modal>
    <confirm-modal :active.sync="isOverwriteModalActive"
      @ok="overwriteOk"
      ok-tooltip="Load but don't overwrite if Calculation IDs are the same"
      @cancel="overwriteCancel"
      cancel-tooltip="Load &amp; overwrite even if Calculation IDs are the same"
      @close="overwriteClose"
      :ok-text="'Load'"
      :cancel-text="'Load & Overwrite'"
      :show-close="true"
      close-text="Cancel">
      <p slot="text-title">Overwrite Quote</p>
      <section slot="text-content">
        <div v-if="isOverwriteModalActive">
          <p>Found matching quote with</p>
          <br>
          <p style="margin-left: 2em">
            Quote No.:
            <span class="has-text-primary has-text-weight-bold">{{ quoteSummaries.quoteNoRef }}</span><br>Calc Id:
            <span class="has-text-primary has-text-weight-bold">{{ quoteSummaries.calculationId }}</span>
          </p>
          <br> Overwrite quote?
        </div>
      </section>
    </confirm-modal>
    <confirm-modal :active.sync="isGotoModalActive"
      @ok="gotoQuote(true)"
      @cancel="gotoQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Go to Quote</p>
      <p slot="text-content">
        <span v-if="quoteSummaries !== null && quoteSummaries.isCreateQuote">Quote created.</span>
        <span v-else>Quote updated.</span>
        Go to quote
        <span class="has-text-primary has-text-weight-bold">{{ `${quoteNo}` }}</span> now?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isCreateSubQuoteModalActive"
      @ok="createSubQuote(true)"
      @cancel="createSubQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Create Supplementary Quote</p>
      <section slot="text-content">
        <div v-if="isCreateSubQuoteModalActive">
          <p>Found new supplementary quote added in
            <span v-if="selectedTask.taskType === audaNetTaskTypes.AudaBridge">AudaBridge</span>
            <span v-else>AudaNet</span>
          </p>
          <br>
          <p style="margin-left: 2em">
            Quote No.:
            <span class="has-text-primary has-text-weight-bold">{{ quoteSummaries.quoteNoRef }}</span><br>Current Supp:
            <span class="has-text-primary has-text-weight-bold">{{ quoteSummaries.subQuoteNo }}</span><br>New supp:
            <span class="has-text-primary has-text-weight-bold">{{ quoteSummaries.createSubQuoteNo }}</span>
          </p>
          <br> Create new supplementary quote?
        </div>
      </section>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import AudanetTaskListSideMenu from './AudanetTaskListSideMenu'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import AudanetTaskService from './AudanetTaskService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { LoadQuoteModal, LoadQuoteSteps, CustomerOptionTypes, Steps } from '@/components/LoadQuoteSteps'
import Guid from '@/components/Guid'
import AudaNetTaskValidation from './AudaNetTaskValidation'
import { ConfirmModal } from '@/components/BulmaModal'
import _cloneDeep from 'lodash/cloneDeep'
import { AudaNetTaskTypes } from '@/enums'
import NavService from '@/components/NavService'
import QuoteRoutes from '@/views/quote/route-types'

export default {
  name: 'AudanetTaskListView',
  components: {
    AppDetailHeader,
    AudanetTaskListSideMenu,
    BulmaTable,
    LoadQuoteModal,
    LoadQuoteSteps,
    ConfirmModal
  },
  mixins: [AudaNetTaskValidation, DateTimeFiltersMixin],
  props: {},
  data() {
    return {
      selectedRow: null,
      tasks: null,
      audanetSetting: null,
      totalRows: 0,
      filter: {
        claimNo: '',
        displayName: '',
        taskStatus: 'open',
        taskType: '',
        businessStatusText: '',
        creationDate: '',
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      isTableLoading: false,
      showNotification: true,
      selectedTask: null,
      quoteSummaries: null,
      isLoadQuoteModalActive: false,
      isCreateSubQuoteModalActive: false,
      currentStep: Steps.CreateAssign.id,
      loadOptions: {
        taskId: '',
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        isOverwriteQuote: false,
        customerOption: CustomerOptionTypes.Create,
        customerId: Guid.empty(),
        customerName: '',
        isPerson: false,
        vehicleId: Guid.empty(),
        isOverwriteVehicle: false,
        businessStatusText: '',
        displayName: '',
        claimNo: '',
        regoNo: '',
        firstname: '',
        lastname: '',
        companyName: '',
        insurerName: '',
        isCreateInsurer: false,
        isAssignQuote: false
      },
      steps: Steps,
      isOverwriteModalActive: false,
      isGotoModalActive: false,
      savedLoadOptions: null,
      quoteNo: '',
      vehicleExists: false
    }
  },
  computed: {
    columns() {
      if (!this.isAutoSyncEnabled) {
        return Columns.filter(x => x.name !== 'syncedDate')
      }
      return Columns
    },
    canLoadMessage() {
      return !this.$licenceExpired
    },
    lastStep() {
      if (this.steps.OverwriteVehicle.enabled) {
        return this.steps.OverwriteVehicle
      } else {
        return this.steps.UpdateCustomer
      }
    },
    enableContinue() {
      return this.lastStep.id === this.currentStep
    },
    audaNetTaskTypes() {
      return AudaNetTaskTypes
    },
    isAutoSyncEnabled() {
      return this.audanetSetting != null && this.audanetSetting.property4 === '1'
    }
  },
  created() {
    this.getTasks()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked() {},
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.getTasks()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getTasks()
    },
    filterList() {
      this.filter.pageIndex = 1
      // this.persistFilter()
      this.getTasks()
    },
    resetList() {
      this.filter.claimNo = ''
      this.filter.displayName = ''
      this.filter.taskStatus = 'open'
      this.filter.taskType = ''
      this.filter.businessStatusText = ''
      this.filter.creationDate = ''
      this.filter.sortColumn = Columns[Columns.length - 2].name
      this.filter.sortOrder = Columns[Columns.length - 2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.getTasks()
    },
    async loadTask() {
      this.$showSpinner('Loading quote')
      try {
        this.isLoadQuoteModalActive = false
        this.isOrmMessageNoModalActive = false
        this.isCreateSubQuoteModalActive = false
        const result = await AudanetTaskService.loadTask(this.loadOptions)
        this.quoteId = result.data
        console.log(this.quoteId)
        this.getTasks()
        if (Guid.validGuid(this.quoteId)) {
          this.quoteNo = await AudanetTaskService.getQuoteNo(this.quoteId)
          this.savedLoadOptions = _cloneDeep(this.loadOptions)
          this.isGotoModalActive = true
        }
      } catch (e) {
        throw e
      } finally {
        this.resetLoadOptions()
        this.$hideSpinner()
      }
    },
    async load(index) {
      this.selectedTask = this.tasks[index]
      // if (this.selectedTask.taskType === AudaNetTaskTypes.AudaBridge) {
      //   return
      // }

      this.$showSpinner('Loading task')
      this.loadOptions.taskId = this.selectedTask.taskId
      this.quoteSummaries = await AudanetTaskService.getQuoteSummaries(this.selectedTask.taskId)
      if (this.quoteSummaries != null && this.quoteSummaries.isCreateQuote) {
        if (this.selectedTask.taskType === AudaNetTaskTypes.AudaBridge) {
          // AudaBridge job
          const jobInfo = await AudanetTaskService.getJobInfo(this.selectedTask.taskId)
          this.assertVehicleExists(jobInfo.rego)
          // console.log(jobInfo)
          this.loadOptions.taskType = AudaNetTaskTypes.AudaBridge
          this.loadOptions.isPerson = jobInfo.companyName === null
          this.loadOptions.insurerName = jobInfo.insurerName
          this.loadOptions.claimNo = this.selectedTask.claimNo
          this.loadOptions.displayName = this.selectedTask.displayName
          this.loadOptions.businessStatusText = this.selectedTask.businessStatusText
          this.loadOptions.regoNo = jobInfo.rego
          if (this.loadOptions.isPerson) {
            this.loadOptions.lastname = jobInfo.lastName
            this.loadOptions.firstname = jobInfo.firstName
            this.loadOptions.customerName = `${jobInfo.lastName}, ${jobInfo.firstName}`
          } else {
            this.loadOptions.customerName = jobInfo.companyName
            this.loadOptions.companyName = jobInfo.companyName
          }
          if (this.quoteSummaries.createSubQuoteNo === 0) {
            console.log('create AudaBridge main')
            this.isLoadQuoteModalActive = true
          } else {
            console.log('create AudaBridge sub')
            this.isCreateSubQuoteModalActive = true
          }
          // this.$notification.openNotificationWithType('warning', 'AudaNet', `${AudaNetTaskTypes.AudaBridge} task not supported yet`)
        } else if (this.selectedTask.taskType === AudaNetTaskTypes.AudaNet) {
          // Create Youi quote
          const jobInfo = await AudanetTaskService.getJobInfo(this.selectedTask.taskId)
          this.assertVehicleExists(jobInfo.rego)
          // console.log(jobInfo)
          this.loadOptions.taskType = AudaNetTaskTypes.AudaNet
          this.loadOptions.isPerson = jobInfo.companyName === null
          this.loadOptions.insurerName = jobInfo.insurerName
          this.loadOptions.claimNo = this.selectedTask.claimNo
          this.loadOptions.displayName = this.selectedTask.displayName
          this.loadOptions.businessStatusText = this.selectedTask.businessStatusText
          this.loadOptions.regoNo = jobInfo.rego
          if (this.loadOptions.isPerson) {
            this.loadOptions.lastname = jobInfo.lastName
            this.loadOptions.firstname = jobInfo.firstName
            this.loadOptions.customerName = `${jobInfo.lastName}, ${jobInfo.firstName}`
          } else {
            this.loadOptions.customerName = jobInfo.companyName
            this.loadOptions.companyName = jobInfo.companyName
          }
          if (this.quoteSummaries.createSubQuoteNo === 0) {
            console.log('create audanet main')
            this.isLoadQuoteModalActive = true
          } else {
            console.log('create audanet supp')
            this.isCreateSubQuoteModalActive = true
          }
        }
      } else if (this.quoteSummaries != null && !this.quoteSummaries.isCreateQuote) {
        // Found qoute, prompt overwrite
        this.loadOptions.quoteId = this.quoteSummaries.quoteId
        this.isOverwriteModalActive = true
      }
      this.$hideSpinner()
    },
    async getTasks() {
      this.isTableLoading = true
      try {
        this.audanetSetting = await AudanetTaskService.getExternalSetting('Audatex')
        this.tasks = await AudanetTaskService.getTasks(this.filter)
        this.totalRows = this.tasks.length > 0 ? this.tasks[0].totalRows : 0
        this.showNotification = true
        this.isTableLoading = false
      } catch (e) {
        this.totalRows = 0
      } finally {
        this.isTableLoading = false
      }
    },
    resetLoadOptions() {
      this.loadOptions.taskId = ''
      this.loadOptions.quoteId = Guid.empty()
      this.loadOptions.insurerId = Guid.empty()
      this.loadOptions.isCreateQuote = true
      this.loadOptions.isOverwriteQuote = false
      this.loadOptions.customerOption = CustomerOptionTypes.Create
      this.loadOptions.customerId = Guid.empty()
      this.loadOptions.customerName = ''
      this.loadOptions.isPerson = false
      this.loadOptions.vehicleId = Guid.empty()
      this.loadOptions.isOverwriteVehicle = false
      this.loadOptions.businessStatusText = ''
      this.loadOptions.displayName = ''
      this.loadOptions.claimNo = ''
      this.loadOptions.regoNo = ''
      this.loadOptions.firstname = ''
      this.loadOptions.lastname = ''
      this.loadOptions.customerName = ''
      this.loadOptions.insurerName = ''
      this.loadOptions.isCreateInsurer = false

      this.currentStep = Steps.CreateAssign.id
      this.selectedMessage = null
      // Reset validation objects
      this.$v.loadOptions.$reset()
    },
    closeModal() {
      this.isLoadQuoteModalActive = false
      this.resetLoadOptions()
    },
    previous() {
      this.currentStep--
      if (this.currentStep === this.steps.CreateAssign.id) {
        this.$v.loadOptions.quoteId.$reset()
      } else if (this.currentStep === this.steps.SelectInsurer.id) {
        this.$v.loadOptions.insurerId.$reset()
      } else if (this.currentStep === this.steps.UpdateCustomer.id) {
        this.$v.loadOptions.customerId.$reset()
      }
    },
    next() {
      switch (this.currentStep) {
        case Steps.CreateAssign.id:
          this.$v.loadOptions.quoteId.$touch()
          if (!this.$v.loadOptions.quoteId.$error || this.loadOptions.isCreateQuote) {
            this.currentStep++
          }
          break
        case Steps.SelectInsurer.id:
          this.$v.loadOptions.insurerId.$touch()
          if (!this.$v.loadOptions.insurerId.$error || this.loadOptions.isCreateInsurer) {
            this.currentStep++
          }
          break
        case Steps.UpdateCustomer.id:
          this.$v.loadOptions.customerId.$touch()
          if (!this.$v.loadOptions.customerId.$error || this.loadOptions.customerOption === CustomerOptionTypes.Create) {
            this.currentStep++
          }
          break
        case Steps.OverwriteVehicle.id:
          break
      }
    },
    async gotoQuote(goto) {
      this.isGotoModalActive = false
      if (goto) {
        const accessModel = await NavService.getAccess('A077', 'detail')
        let url = ''
        if (accessModel.version === '2') {
          const routeData = this.$router.resolve({
            name: QuoteRoutes.QuoteDetail.name,
            params: { quoteId: this.quoteId }
          })
          url = routeData.href
        } else {
          url = `${process.env.VUE_APP_ROOT_URI}/modules/handlers/QuoteLoading.ashx?quoteId=${this.quoteId}&mode=5`
        }
        window.open(url, '_blank')
      }
    },
    overwriteOk() {
      this.loadOptions.isOverwriteQuote = false
      this.isOverwriteModalActive = false
      this.loadTask()
    },
    overwriteCancel() {
      this.loadOptions.isOverwriteQuote = true
      this.isOverwriteModalActive = false
      this.loadTask()
    },
    overwriteClose() {
      this.isOverwriteModalActive = false
    },
    async assertVehicleExists(rego) {
      this.vehicleExists = await AudanetTaskService.assertVehicleExists(rego)
      this.steps.OverwriteVehicle.enabled = this.vehicleExists
    },
    async createSubQuote(create) {
      if (create) {
        this.isCreateSubQuoteModalActive = false
        this.$showSpinner('Creating supplementry')
        const result = await AudanetTaskService.createSubQuote(this.quoteSummaries.quoteId)
        this.quoteId = result.data
        this.loadOptions.quoteId = this.quoteId
        this.$hideSpinner()
        this.loadTask() // updateQuotes
      } else {
        this.isCreateSubQuoteModalActive = false
      }
    },

    checkSettingStatus(businessStatusText, audanetSetting) {
      // check if the task is created or RFQ Received and audanet setting is not null and property4 is 1
      // property 4 indicates that the audanet sync function is enabled
      return (businessStatusText === 'Created' || businessStatusText === 'RFQ Received') && audanetSetting != null && audanetSetting.property4 === '1'
    },
    getIconToolTip(businessStatusText, audanetSetting) {
      if (this.checkSettingStatus(businessStatusText, audanetSetting)) {
        return 'AudaNet auto sync is enabled, load disabled'
      }
      return 'Load Message'
    }
  }
}
</script>
